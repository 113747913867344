import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { login } from "./../../store/actions/user.actions";
import { getLoginValidationSchema } from "../../common/validators/forms/login";

export default connect((s) => ({}), {
  login,
})(({ login }) => {
  const submitLogin = (values) => login(values);

  return (
    <div className="login-container">
      <Formik
        validationSchema={getLoginValidationSchema}
        initialValues={{
          login: "",
          password: "",
        }}
        onSubmit={submitLogin}>
        {({ setFieldValue, handleSubmit, values }) => (
          <div className="login-form">
            <h2>Авторизация</h2>
            <input
              className="input-default"
              placeholder="Логин"
              value={values.login}
              onChange={(e) => setFieldValue("login", e.target.value)}
            />
            <input
              className="input-default"
              placeholder="Пароль"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
            />
            <button
              className="button-default"
              type={"button"}
              onClick={handleSubmit}>
              Войти
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
});
