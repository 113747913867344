import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import Header from "../Header";

import LoginPage from "../../pages/Login";
import ProfilePage from "../../pages/Profile";
import UsersPage from "../../pages/Users";
import ShipmentsPage from "../../pages/Shipments";

import {
  getAccount,
  logout,
  clearUserError,
} from "./../../store/actions/user.actions";

const RestrictAccessRoute = ({
  exact, path, render, forAuth = true, forRoles = [], role, auth, to, roleTo,
}) => {
  if (
    (forRoles.length > 0 &&
      forRoles.indexOf(role) != -1 &&
      ((!forAuth && !auth) || (forAuth && auth))) ||
    (forRoles.length == 0 && ((!forAuth && !auth) || (forAuth && auth)))
  ) {
    return <Route exact={exact} path={path} render={render} />;
  } else if (path == "/login" && !auth) {
    return <Redirect to={to} />;
  } else if (
    forRoles.length > 0 &&
    forRoles.indexOf(role) == -1 &&
    ((!forAuth && !auth) || (forAuth && auth))
  ) {
    return <Redirect to={roleTo} />;
  } else {
    return <Redirect to={to} />;
  }
};

export default connect((s) => ({
  userLanguage: s.user.language,
  isLoggedIn: s.user.isLoggedIn,
  user: s.user.state,
  userError: s.user.error,
}), {
  getAccount,
  logout,
  clearUserError,
})(({
  userLanguage,
  isLoggedIn,
  user,
  userError,

  getAccount,
  logout,
  clearUserError,
}) => {
  const history = useHistory();
  const [PathName, changePathName] = useState(location.pathname);

  useEffect(() => {
    return history.listen((location) => changePathName(location.pathname));
  }, [history]);

  React.useEffect(() => {
    getAccount();
  }, [isLoggedIn]);

  useEffect(() => {
    if (userError) {
      toast.error(userError[userLanguage], {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      clearUserError();
    }
  }, [userError]);

  return (
    <>
      <ToastContainer />

      {["/login"].indexOf(PathName) == -1 && <Header logout={logout} />}

      <Switch>
        <RestrictAccessRoute
          exact={true}
          path="/login"
          render={() => <LoginPage />}
          forAuth={false}
          auth={user}
          to={"/"}
        />

        <RestrictAccessRoute
          exact={true}
          path="/"
          render={() => <UsersPage />}
          forAuth={true}
          auth={user}
          to={"/login"}
        />
        <RestrictAccessRoute
          exact={true}
          path="/profile"
          render={() => <ProfilePage />}
          forAuth={true}
          auth={user}
          to={"/login"}
        />
        <RestrictAccessRoute
          exact={true}
          path="/users"
          render={() => <UsersPage />}
          forAuth={true}
          auth={user}
          to={"/login"}
        />
        <RestrictAccessRoute
          exact={true}
          path="/shipments"
          render={() => <ShipmentsPage />}
          forAuth={true}
          auth={user}
          to={"/login"}
        />

        <Route render={() => <ProfilePage />} />
      </Switch>
    </>
  );
});
