import React from "react";
import { Link } from "react-router-dom";

export default ({ logout }) => {
  return (
    <div className="header-container">
      <div className="header-nav-container">
        <Link className={"header-nav-link"} to={"/users"}>
          Пользователи
        </Link>
        <Link className={"header-nav-link"} to={"/shipments"}>
          Грузоперевозки
        </Link>
        <Link className={"header-nav-link"} to={"/profile"}>
          Профиль
        </Link>
      </div>
      <div className="header-exit-container">
        <button onClick={logout} className="button-default">
          Выйти
        </button>
      </div>
    </div>
  );
};
