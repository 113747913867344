import { takeEvery } from "redux-saga/effects";
import { bindAsyncActions } from "../../common/store/helpers";
import {
  getShipments,
  getShipmentsAsync,
  closeShipment,
  closeShipmentAsync,
  changeShipmentsFilter,
  changeShipmentsFilterAsync,
  clearShipmentsError,
  clearShipmentsErrorAsync,
} from "../actions/shipments.actions";
import ShipmentsApi from "../../common/api/shipments.api";

function plugeWorker() {
  return true;
}

function changeShipmentsFilterWorker(filter) {
  return filter;
}

export function* shipmentsSaga() {
  yield takeEvery(
    getShipments,
    bindAsyncActions(getShipmentsAsync)(ShipmentsApi.getShipmentsEndpoint)
  );
  yield takeEvery(
    closeShipment,
    bindAsyncActions(closeShipmentAsync)(ShipmentsApi.closeShipmentEndpoint)
  );
  yield takeEvery(
    changeShipmentsFilter,
    bindAsyncActions(changeShipmentsFilterAsync)(changeShipmentsFilterWorker)
  );
  yield takeEvery(
    clearShipmentsError,
    bindAsyncActions(clearShipmentsErrorAsync)(plugeWorker)
  );
}
