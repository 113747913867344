import createRootReducer from "./reducers/createRootReducer";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";

import { routerMiddleware } from "connected-react-router";
import { persistReducer, persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import { applyMiddleware, createStore } from "redux";

const persistConfig = {
  key: "cargo-transportation-admin",
  storage,
  blacklist: [],
};

const loggerActionColors = {
  success: "green",
  failed: "red",
  started: "blue",
};

const sagaMiddleware = createSagaMiddleware();

const devMiddlewares =
  process.env.NODE_ENV === "development"
    ? [
        createLogger({
          collapsed: true,
          duration: true,
          colors: {
            title: (action) => loggerActionColors[action.type.split(".")[1]],
          },
        }),
      ]
    : [];

export default (history) => {
  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
  );

  const store = createStore(
    persistedReducer,
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      ...devMiddlewares
    )
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);
  return { store, persistor };
};
