import React from "react";
import { Helmet } from "react-helmet";

import ShipmentsComponent from "../components/Shipments";

export default ({}) => {
  return (
    <>
      <Helmet>
        <title>Cargo Transportation Admin - Грузоперевозки</title>
      </Helmet>
      <ShipmentsComponent />
    </>
  );
};
