import { handleActions } from "redux-actions";
import {
  getShipmentsAsync,
  closeShipmentAsync,
  changeShipmentsFilterAsync,
  clearShipmentsErrorAsync,
} from "../actions/shipments.actions";

import languages from "../../common/languages";

const initialState = {
  state: [],
  count: 0,
  filter: {
    _id: "",
    createdAt: null,
    phone: "",
  },
  error: null,
};

export default handleActions(
  {
    [getShipmentsAsync.success]: (s, { payload: { data: RequestedData } }) => ({
      ...s,
      state: RequestedData.success ? RequestedData.result.shipments : [],
      count: RequestedData.success ? RequestedData.result.count : 0,
      error: RequestedData.success
        ? null
        : RequestedData.error
        ? RequestedData.error
        : languages.errors.default,
    }),
    [closeShipmentAsync.success]: (
      s,
      { payload: { data: RequestedData } }
    ) => ({
      ...s,
      state: RequestedData.success
        ? s.state.map((v) =>
            v._id == RequestedData.result.shipment._id
              ? { ...v, status: RequestedData.result.shipment.status }
              : v
          )
        : s.state,
      error: RequestedData.success
        ? null
        : RequestedData.error
        ? RequestedData.error
        : languages.errors.default,
    }),
    [changeShipmentsFilterAsync.success]: (s, { payload: filter }) => ({
      ...s,
      filter: filter,
    }),
    [clearShipmentsErrorAsync.success]: (s, a) => ({
      ...s,
      error: null,
    }),

    [getShipmentsAsync.failed]: (s, a) => ({
      ...s,
      error: languages.errors.default,
    }),
    [closeShipmentAsync.failed]: (s, a) => ({
      ...s,
      error: languages.errors.default,
    }),
  },
  initialState
);
