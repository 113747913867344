import { takeEvery } from "redux-saga/effects";
import { bindAsyncActions } from "../../common/store/helpers";
import {
  login,
  loginAsync,
  logout,
  logoutAsync,
  changeAccountPassword,
  changeAccountPasswordAsync,
  getAccount,
  getAccountAsync,
  changeAccountField,
  changeAccountFieldAsync,
  clearUserError,
  clearUserErrorAsync,
} from "../actions/user.actions";
import authApi from "../../common/api/user.api";

function plugeWorker() {
  return true;
}

function changeAccountInfoFieldWorker({ name, value }) {
  return { name, value };
}

export function* userSaga() {
  yield takeEvery(login, bindAsyncActions(loginAsync)(authApi.loginEndpoint));
  yield takeEvery(
    logout,
    bindAsyncActions(logoutAsync)(authApi.logoutEndpoint)
  );
  yield takeEvery(
    changeAccountPassword,
    bindAsyncActions(changeAccountPasswordAsync)(
      authApi.changeAccountPasswordEndpoint
    )
  );
  yield takeEvery(
    getAccount,
    bindAsyncActions(getAccountAsync)(authApi.getAccountEndpoint)
  );
  yield takeEvery(
    changeAccountField,
    bindAsyncActions(changeAccountFieldAsync)(changeAccountInfoFieldWorker)
  );
  yield takeEvery(
    clearUserError,
    bindAsyncActions(clearUserErrorAsync)(plugeWorker)
  );
}
