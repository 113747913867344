import React from 'react';
import { Helmet } from 'react-helmet';

import LoginComponent from '../components/Login';

export default ({
}) => {

  return (
    <>
      <Helmet>
        <title>Cargo Transportation Admin - Авторизация</title>
      </Helmet>
      <LoginComponent />
    </>
  );

};