import "babel-polyfill";
import "./css/style.sass";
import "react-toastify/dist/ReactToastify.css";
import "react-google-flight-datepicker/dist/main.css";
import "sweetalert2/src/sweetalert2.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";

import createStore from "./store/createStore";
import AppLayout from "./components/AppLayout";

const history = createBrowserHistory();
const { store, persistor } = createStore(history);
const App = () => {
  // persistor.purge();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <AppLayout />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

createRoot(document.getElementById("app")).render(<App />);
