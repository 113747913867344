import axios from "axios";
import { baseUrl } from "../config";

axios.defaults.withCredentials = true;

export default class UsersApi {
  static changeUserBlockStatusEndpoint = (data) =>
    axios.put(`${baseUrl}admin/toggle-user-block-status`, data);
  static getUsersEndpoint = (params) =>
    axios.get(`${baseUrl}admin/all-users`, { params });
}
