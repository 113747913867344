import { handleActions } from "redux-actions";
import {
  loginAsync,
  logoutAsync,
  changeAccountPasswordAsync,
  getAccountAsync,
  changeAccountFieldAsync,
  clearUserErrorAsync,
} from "../actions/user.actions";

import languages from "../../common/languages";

const initialState = {
  state: null,
  language: "RU",
  isLoggedIn: null,
  passwordChanged: null,
  error: null,
};

export default handleActions(
  {
    [loginAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
      ...s,
      isLoggedIn: requestData.success ? true : false,
      error: requestData.success
        ? null
        : requestData.error
        ? requestData.error
        : languages.errors.default,
    }),
    [logoutAsync.success]: (s, { payload: { data: requestData } } = {}) => ({
      ...s,
      isLoggedIn: false,
    }),
    [changeAccountPasswordAsync.success]: (
      s,
      { payload: { data: requestData } } = {}
    ) => ({
      ...s,
      passwordChanged: requestData.success ? true : false,
      error: requestData.success
        ? null
        : requestData.error
        ? requestData.error
        : languages.errors.default,
    }),
    [getAccountAsync.success]: (
      s,
      { payload: { response, data: requestData } } = {}
    ) => ({
      ...s,
      state: requestData.success ? requestData.result.user : null,
      isLoggedIn: response && response.status == 401 ? false : s.isLoggedIn,
      error:
        response && response.statusText == "Unauthorized"
          ? null
          : requestData.success
          ? null
          : requestData.error
          ? requestData.error
          : languages.errors.default,
    }),
    [changeAccountFieldAsync.success]: (s, { payload: { name, value } }) => ({
      ...s,
      [name]: value,
    }),
    [clearUserErrorAsync.success]: (s, a) => ({ ...s, error: null }),

    [getAccountAsync.failed]: (s, { payload: { response } } = {}) => ({
      ...s,
      state: response && response.status == 401 ? null : s.state,
      isLoggedIn: response && response.status == 401 ? false : s.isLoggedIn,
      error:
        response && response.status == 401 ? null : languages.errors.default,
    }),
    [loginAsync.failed]: (s, { payload: { response } } = {}) => ({
      ...s,
      error:
        !response.data?.success && response.data?.error
          ? response.data?.error
          : languages.errors.default,
    }),
    [changeAccountPasswordAsync.failed]: (s, a) => ({
      ...s,
      error: languages.errors.default,
    }),
    [clearUserErrorAsync.failed]: (s, a) => ({ ...s, error: null }),
  },
  initialState
);
