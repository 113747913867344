import { createActionFactory } from "../../common/store/helpers";

const factory = createActionFactory("SHIPMENTS");

export const getShipments = factory.create("GET_SHIPMENTS");
export const getShipmentsAsync = factory.createAsync("GET_SHIPMENTS_ASYNC");

export const closeShipment = factory.create("CLOSE_SHIPMENT");
export const closeShipmentAsync = factory.createAsync("CLOSE_SHIPMENT_ASYNC");

export const changeShipmentsFilter = factory.create("CHANGE_SHIPMENTS_FILTER");
export const changeShipmentsFilterAsync = factory.createAsync(
  "CHANGE_SHIPMENTS_FILTER_ASYNC"
);

export const clearShipmentsError = factory.create("CLEAR_ERROR");
export const clearShipmentsErrorAsync =
  factory.createAsync("CLEAR_ERROR_ASYNC");
