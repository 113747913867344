import React from "react";
import { Helmet } from "react-helmet";

import ProfileComponent from "../components/Profile";

export default ({}) => {
  return (
    <>
      <Helmet>
        <title>Cargo Transportation Admin - Профиль</title>
      </Helmet>
      <ProfileComponent />
    </>
  );
};
