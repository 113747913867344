import React from "react";
import InputMask from "react-input-mask";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { connect } from "react-redux";
import { Formik } from "formik";
import { RangeDatePicker } from "react-google-flight-datepicker";

import { parseFullTime } from "../../common/utils";

import {
  getUsers,
  changeUserBlockStatus,
  changeUsersFilter,
} from "./../../store/actions/users.actions";

export default connect(
  (s) => ({
    usersFilter: s.users.filter,
    users: s.users.state,
    usersCount: s.users.count,
  }),
  {
    getUsers,
    changeUserBlockStatus,
    changeUsersFilter,
  }
)(
  ({
    usersFilter,
    users,
    usersCount,

    getUsers,
    changeUserBlockStatus,
    changeUsersFilter,
  }) => {
    const [CurrentPage, CurrentPageChange] = React.useState(0);

    const submitChangeUsersFilter = (values) => changeUsersFilter(values);

    const submitBlockUser = (user) =>
      Swal.fire({
        title: "Подтвердите действие",
        text: user.blocked
          ? "Вы уверены, что хотите разблокировать этого пользователя?"
          : "Вы уверены, что хотите заблокировать этого пользователя?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: user.blocked
          ? "Да, разблокировать"
          : "Да, заблокировать",
        cancelButtonText: "Нет",
      }).then((result) => {
        if (result.isConfirmed)
          changeUserBlockStatus({ user: user._id, blocked: !user.blocked });
      });

    React.useEffect(() => {
      getUsers({ ...usersFilter, from: CurrentPage * 20 });
    }, [usersFilter, CurrentPage]);

    return (
      <div className="users-page">
        <h2>Пользователи</h2>
        <Formik initialValues={usersFilter} onSubmit={submitChangeUsersFilter}>
          {({ setFieldValue, handleSubmit, values }) => (
            <div className="users-page-filters-form">
              <RangeDatePicker
                startDate={values.registerAtFrom}
                endDate={values.registerAtTo}
                onChange={(startDate, endDate) => {
                  setFieldValue("registerAtFrom", startDate);
                  setFieldValue("registerAtTo", endDate);
                }}
                maxDate={new Date()}
                dateFormat="DD.MM.YY"
                monthFormat="MMM YYYY"
                startDatePlaceholder="Зарегестрирован от"
                endDatePlaceholder="Зарегестрирован до"
                disabled={false}
                startWeekDay="monday"
              />
              <div className="users-page-filters-form-select-wrapper">
                <span>Верифицирован</span>
                <select
                  className="input-default"
                  value={values.verifyed}
                  onChange={(e) => setFieldValue("verifyed", e.target.value)}>
                  <option value="">Любое</option>
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </select>
              </div>
              <div className="users-page-filters-form-select-wrapper">
                <span>Заблокирован</span>
                <select
                  className="input-default"
                  value={values.blocked}
                  onChange={(e) => setFieldValue("blocked", e.target.value)}>
                  <option value="">Любое</option>
                  <option value="true">Да</option>
                  <option value="false">Нет</option>
                </select>
              </div>
              <div className="users-page-filters-form-select-wrapper">
                <span>Номер телефна</span>
                <InputMask
                  className="input-default"
                  mask={"+380 99 999 99 99"}
                  value={values.phone}
                  onChange={(e) =>
                    setFieldValue(
                      "phone",
                      e.target.value.split(" ").join("").replace("+", "")
                    )
                  }
                />
              </div>
              <button
                className="button-default"
                type="button"
                onClick={handleSubmit}>
                Сохранить
              </button>
            </div>
          )}
        </Formik>

        <div className="users-page-table-wrapper">
          <table className="users-page-table">
            <thead>
              <tr>
                <th className="users-page-table-item-cell">Имя</th>
                <th className="users-page-table-item-cell">Зарегестрирован</th>
                <th className="users-page-table-item-cell">Номер</th>
                <th className="users-page-table-item-cell">Транспорт</th>
                <th className="users-page-table-item-cell">Верификация</th>
                <th className="users-page-table-item-cell">оценка</th>
                <th className="users-page-table-item-cell">Действия</th>
              </tr>
            </thead>
            <tbody>
              {users.map((v, i) => (
                <tr className="users-page-table-item" key={i}>
                  <td className="users-page-table-item-cell">
                    {v.lastName} {v.firstName}
                  </td>
                  <td className="users-page-table-item-cell">
                    {parseFullTime(v.createdAt)}
                  </td>
                  <td className="users-page-table-item-cell">{v.phone}</td>
                  <td className="users-page-table-item-cell">
                    {v.transport
                      ? `${v.transport.mark} ${v.transport.model}, ${v.transport.year}`
                      : "Не указан"}
                  </td>
                  <td className="users-page-table-item-cell">
                    {
                      v.verification.passed && <>
                        <span>{v.verification.name}</span>
                        <br />
                        <span>{v.verification.city}</span>
                      </>
                    }
                  </td>
                  <td className="users-page-table-item-cell ">
                    <span>Водитель: {v.rate.driver}</span>
                    <br />
                    <span>Заказчик: {v.rate.customer}</span>
                  </td>
                  <td className="users-page-table-item-cell">
                    <button
                      className="button-default"
                      onClick={() => submitBlockUser(v)}>
                      {v.blocked ? "Разблокировать" : "Заблокировать"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="page-pagination">
          <button
            className="button-default"
            type="button"
            onClick={() =>
              CurrentPage > 0 ? CurrentPageChange(CurrentPage - 1) : null
            }>
            Назад
          </button>
          <span className="page-pagination-pages-count">
            {CurrentPage + 1}/{Math.ceil(usersCount / 20)}
          </span>
          <button
            className="button-default"
            type="button"
            onClick={() =>
              CurrentPage + 1 < Math.ceil(usersCount / 20)
                ? CurrentPageChange(CurrentPage + 1)
                : null
            }>
            Вперед
          </button>
        </div>
      </div>
    );
  }
);
