import { createActionFactory } from "../../common/store/helpers";

const factory = createActionFactory("ADMIN");

export const login = factory.create("LOGIN_IN_ACCOUNT");
export const loginAsync = factory.createAsync("LOGIN_IN_ACCOUNT_ASYNC");

export const logout = factory.create("LOGOUT_IN_ACCOUNT");
export const logoutAsync = factory.createAsync("LOGOUT_IN_ACCOUNT_ASYNC");

export const changeAccountPassword = factory.create("CHANGE_ACCOUNT_PASSWORD");
export const changeAccountPasswordAsync = factory.createAsync(
  "CHANGE_ACCOUNT_PASSWORD_ASYNC"
);

export const getAccount = factory.create("GET_CURRENT_ACCOUNT_INFO");
export const getAccountAsync = factory.createAsync(
  "GET_CURRENT_ACCOUNT_INFO_ASYNC"
);

export const changeAccountField = factory.create("CHANGE_ACCOUNT_FIELD");
export const changeAccountFieldAsync = factory.createAsync(
  "CHANGE_ACCOUNT_FIELD_ASYNC"
);

export const clearUserError = factory.create("CLEAR_ERROR");
export const clearUserErrorAsync = factory.createAsync("CLEAR_ERROR_ASYNC");
