// export const baseUrl = "http://localhost:8000/api/v1/";
export const baseUrl = "https://api.okril.com/api/v1/";
export const shipmentsStatuses = {
  acceptBids: "Принимает заявки",
  accepted: "Водитель выбран",
  goToUpload: "Движется на загрузку",
  upload: "Загружается",
  carryingCargo: "В пути",
  unload: "Разгружается",
  cancelled: "Отменён",
  complete: "Закончен",
};
