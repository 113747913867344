import axios from "axios";
import { baseUrl } from "../config";

axios.defaults.withCredentials = true;

export default class UsersApi {
  static closeShipmentEndpoint = (data) =>
    axios.put(`${baseUrl}admin/close-shipment`, data);
  static getShipmentsEndpoint = (params) =>
    axios.get(`${baseUrl}admin/all-shipments`, { params });
}
