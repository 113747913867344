import axios from "axios";
import { baseUrl } from "../config";

axios.defaults.withCredentials = true;

export default class UserApi {
  static loginEndpoint = (data) => axios.post(`${baseUrl}admin/login`, data);
  static logoutEndpoint = () => axios.post(`${baseUrl}admin/logout`);
  static changeAccountPasswordEndpoint = (data) =>
    axios.put(`${baseUrl}admin/change-password`, data);
  static getAccountEndpoint = () => axios.get(`${baseUrl}admin/account-info`);
}
