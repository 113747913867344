import React from "react";
import { Helmet } from "react-helmet";

import UsersComponent from "../components/Users";

export default ({}) => {
  return (
    <>
      <Helmet>
        <title>Cargo Transportation Admin - Пользователи</title>
      </Helmet>
      <UsersComponent />
    </>
  );
};
