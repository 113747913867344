import { takeEvery } from "redux-saga/effects";
import { bindAsyncActions } from "../../common/store/helpers";
import {
  getUsers,
  getUsersAsync,
  changeUserBlockStatus,
  changeUserBlockStatusAsync,
  changeUsersFilter,
  changeUsersFilterAsync,
  clearUsersError,
  clearUsersErrorAsync,
} from "../actions/users.actions";
import UsersApi from "../../common/api/users.api";

function plugeWorker() {
  return true;
}

function changeUsersFilterWorker(filter) {
  return { filter };
}

export function* usersSaga() {
  yield takeEvery(
    getUsers,
    bindAsyncActions(getUsersAsync)(UsersApi.getUsersEndpoint)
  );
  yield takeEvery(
    changeUserBlockStatus,
    bindAsyncActions(changeUserBlockStatusAsync)(
      UsersApi.changeUserBlockStatusEndpoint
    )
  );
  yield takeEvery(
    changeUsersFilter,
    bindAsyncActions(changeUsersFilterAsync)(changeUsersFilterWorker)
  );
  yield takeEvery(
    clearUsersError,
    bindAsyncActions(clearUsersErrorAsync)(plugeWorker)
  );
}
