import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import userReducer from "./user.reducer";
import usersReducer from "./users.reducer";
import shipmentsReducer from "./shipments.reducer";

export default (history) =>
  combineReducers({
    user: userReducer,
    users: usersReducer,
    shipments: shipmentsReducer,
    router: connectRouter(history),
  });
