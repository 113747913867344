import { createActionFactory } from "../../common/store/helpers";

const factory = createActionFactory("USERS");

export const getUsers = factory.create("GET_USERS");
export const getUsersAsync = factory.createAsync("GET_USERS_ASYNC");

export const changeUserBlockStatus = factory.create("CHANGE_USER_BLOCK_STATUS");
export const changeUserBlockStatusAsync = factory.createAsync(
  "CHANGE_USER_BLOCK_STATUS_ASYNC"
);

export const changeUsersFilter = factory.create("CHANGE_USERS_FILTER");
export const changeUsersFilterAsync = factory.createAsync(
  "CHANGE_USERS_FILTER_ASYNC"
);

export const clearUsersError = factory.create("CLEAR_ERROR");
export const clearUsersErrorAsync = factory.createAsync("CLEAR_ERROR_ASYNC");
