import React from "react";
import InputMask from "react-input-mask";
import SwalRaw from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { connect } from "react-redux";
import { Formik } from "formik";
import { SingleDatePicker } from "react-google-flight-datepicker";

import { parseDate, parseFullTime } from "../../common/utils";

import {
  getShipments,
  closeShipment,
  changeShipmentsFilter,
} from "./../../store/actions/shipments.actions";
import { shipmentsStatuses } from "../../common/config";

const Swal = withReactContent(SwalRaw);

export default connect((s) => ({
  shipmentsFilter: s.shipments.filter,
  shipments: s.shipments.state,
  shipmentsCount: s.shipments.count,
}), {
  getShipments,
  closeShipment,
  changeShipmentsFilter,
})(({
  shipmentsFilter,
  shipments,
  shipmentsCount,

  getShipments,
  closeShipment,
  changeShipmentsFilter,
}) => {
  const [CurrentPage, CurrentPageChange] = React.useState(0);

  const submitChangeShipmentsFilter = (values) =>
    changeShipmentsFilter(values);

  const submitCloseUser = (cargo) =>
    Swal.fire({
      title: "Подтвердите действие",
      text: "Вы уверены, что хотите закрыть эту грузоперевозку?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да, закрыть",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) closeShipment({ cargo });
    });

  const viewFullShipmentInfo = (shipment) =>
    Swal.fire({
      title: "Полная информация по грузоперевозке",
      showConfirmButton: false,
      showDenyButton: false,
      showCancelButton: false,
      showCloseButton: true,
      html: (
        <div className="shipment-full-info">
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">Заказчик</span>
            <span className="shipment-full-info-block-text">
              Имя: {shipment.customer.lastName} {shipment.customer.firstName}
            </span>
            <span className="shipment-full-info-block-text">
              Рейтинг: {shipment.customer.rate.customer}
            </span>
            <span className="shipment-full-info-block-text">
              Верифицирован:{" "}
              {shipment.customer.verification.passed ? "Да" : "Нет"}
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">Водитель</span>
            {shipment.driver ? (
              <>
                <span className="shipment-full-info-block-text">
                  Имя: {shipment.driver.lastName} {shipment.driver.firstName}
                </span>
                <span className="shipment-full-info-block-text">
                  Рейтинг: {shipment.driver.rate.driver}
                </span>
                <span className="shipment-full-info-block-text">
                  Верифицирован:{" "}
                  {shipment.driver.verification.passed ? "Да" : "Нет"}
                </span>
                <span className="shipment-full-info-block-text">
                  Транспорт: {shipment.transport.mark}{" "}
                  {shipment.transport.mark},{" "}
                  {shipment.transport.year}
                </span>
              </>
            ) : (
              <span className="shipment-full-info-block-text">
                Не назначен
              </span>
            )}
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">
              Статус:{" "}
              <span className="shipment-full-info-block-text">
                {shipmentsStatuses[shipment.status]}
              </span>
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">Откуда</span>
            <span className="shipment-full-info-block-text">
              Город: {shipment.from.city}
            </span>
            <span className="shipment-full-info-block-text">
              Улица: {shipment.from.address}
            </span>
            <span className="shipment-full-info-block-text">
              Дом: {shipment.from.home}
            </span>
            <span className="shipment-full-info-block-text">
              Номер загрузки: {shipment.from.phone}
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">Куда</span>
            <span className="shipment-full-info-block-text">
              Город: {shipment.to.city}
            </span>
            <span className="shipment-full-info-block-text">
              Улица: {shipment.to.address}
            </span>
            <span className="shipment-full-info-block-text">
              Дом: {shipment.to.home}
            </span>
            <span className="shipment-full-info-block-text">
              Номер разгрузки: {shipment.to.phone}
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">
              Погрузка:{" "}
              <span className="shipment-full-info-block-text">
                {parseDate(shipment.upload.from)} -{" "}
                {parseDate(shipment.upload.to)}
              </span>
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">
              Дполнительная информация
            </span>
            <span className="shipment-full-info-block-text">
              Описание: {shipment.description}
            </span>
            <span className="shipment-full-info-block-text">
              Вес: {shipment.weight}
            </span>
            <span className="shipment-full-info-block-text">
              Объем: {shipment.volume}
            </span>
            <span className="shipment-full-info-block-text">
              Оплата: {shipment.payment}
            </span>
            <span className="shipment-full-info-block-text">
              Цена: {shipment.price} грн.
            </span>
            <span className="shipment-full-info-block-text">
              Кузов: {shipment.body}
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">
              Оценка водителя:{" "}
              <span className="shipment-full-info-block-text">
                {shipment.reviewDriver
                  ? shipment.reviewDriver.rating
                  : "отсутствует"}
              </span>
            </span>
          </div>
          <div className="shipment-full-info-block">
            <span className="shipment-full-info-block-title">
              Оценка заказчика:{" "}
              <span className="shipment-full-info-block-text">
                {shipment.reviewCustomer
                  ? shipment.reviewCustomer.rating
                  : "отсутствует"}
              </span>
            </span>
          </div>
        </div>
      ),
    });

  React.useEffect(() => {
    getShipments({ ...shipmentsFilter, from: CurrentPage * 20 });
  }, [shipmentsFilter, CurrentPage]);

  return (
    <div className="shipments-page">
      <h2>Грузоперевозки</h2>
      <Formik
        // validationSchema={getLoginValidationSchema}
        initialValues={shipmentsFilter}
        onSubmit={submitChangeShipmentsFilter}>
        {({ setFieldValue, handleSubmit, values }) => (
          <div className="shipments-page-filters-form">
            <div className="shipments-page-filters-form-select-wrapper">
              <span>ID грузоперевозки</span>
              <input
                className="input-default"
                value={values._id}
                onChange={(e) => setFieldValue("_id", e.target.value)}
              />
            </div>
            <div className="shipments-page-filters-form-select-wrapper">
              <span>Номер телефна</span>
              <InputMask
                className="input-default"
                mask={"+380 99 999 99 99"}
                value={values.phone}
                onChange={(e) =>
                  setFieldValue(
                    "phone",
                    e.target.value.split(" ").join("").replace("+", "")
                  )
                }
              />
            </div>
            <SingleDatePicker
              startDate={values.createdAt}
              onChange={(startDate) => setFieldValue("createdAt", startDate)}
              maxDate={new Date()}
              dateFormat="DD.MM.YY"
              monthFormat="MMM YYYY"
              startDatePlaceholder="Создан"
              disabled={false}
              startWeekDay="monday"
            />
            <button
              className="button-default"
              type="button"
              onClick={handleSubmit}>
              Сохранить
            </button>
          </div>
        )}
      </Formik>
      <div className="shipments-page-table-wrapper">
        <table className="shipments-page-table">
          <thead>
            <tr>
              <th className="shipments-page-table-item-cell">Создана</th>
              <th className="shipments-page-table-item-cell">Откуда</th>
              <th className="shipments-page-table-item-cell">Куда</th>
              <th className="shipments-page-table-item-cell">Заказчик</th>
              <th className="shipments-page-table-item-cell">Действия</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((v, i) => (
              <tr className="shipments-page-table-item" key={i}>
                <td className="shipments-page-table-item-cell">
                  {parseFullTime(v.createdAt)}
                </td>
                <td className="shipments-page-table-item-cell">
                  {v.from.city}, {v.from.address}, {v.from.home}
                </td>
                <td className="shipments-page-table-item-cell">
                  {v.to.city}, {v.to.address}, {v.to.home}
                </td>
                <td className="shipments-page-table-item-cell">
                  {v.customer.lastName} {v.customer.firstName}
                </td>
                <td className="shipments-page-table-item-cell">
                  <button
                    className="button-default"
                    disabled={v.status == "cancelled"}
                    onClick={() => submitCloseUser(v._id)}>
                    Закрыть
                  </button>
                  <button
                    className="button-default"
                    onClick={() => viewFullShipmentInfo(v)}>
                    Просмотр
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="page-pagination">
        <button
          className="button-default"
          type="button"
          onClick={() =>
            CurrentPage > 0 ? CurrentPageChange(CurrentPage - 1) : null
          }>
          Назад
        </button>
        <span className="page-pagination-pages-count">
          {CurrentPage + 1}/{Math.ceil(shipmentsCount / 20)}
        </span>
        <button
          className="button-default"
          type="button"
          onClick={() =>
            CurrentPage + 1 < Math.ceil(shipmentsCount / 20)
              ? CurrentPageChange(CurrentPage + 1)
              : null
          }>
          Вперед
        </button>
      </div>
    </div>
  );
});
