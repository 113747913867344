import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Formik } from "formik";

import {
  changeAccountPassword,
  changeAccountField,
} from "./../../store/actions/user.actions";

import { changePasswordValidationSchema } from "../../common/validators/forms/profile";

export default connect(
  (s) => ({
    passwordChanged: s.user.passwordChanged,
  }),
  {
    changeAccountPassword,
    changeAccountField,
  }
)(({ passwordChanged, changeAccountPassword, changeAccountField }) => {
  const submitChangePassword = (values, { resetForm }) => {
    changeAccountPassword(values);

    resetForm();
  };

  React.useEffect(() => {
    if (passwordChanged)
      toast.success("Пароль изменён успешно!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    if (passwordChanged != null)
      changeAccountField({ name: "passwordChanged", value: null });
  }, [passwordChanged]);

  return (
    <div className="profile-page">
      <h2>Профиль</h2>
      <div className="profile-page-change-password-wrapper">
        <h3>Изменить пароль</h3>
        <Formik
          validationSchema={changePasswordValidationSchema}
          initialValues={{
            password: "",
            checkPassword: "",
          }}
          onSubmit={submitChangePassword}>
          {({ setFieldValue, handleSubmit, values }) => (
            <div className="profile-page-change-password-content">
              <input
                className="profile-page-change-password-input"
                onChange={(e) => setFieldValue("password", e.target.value)}
                value={values.password}
                type="text"
                placeholder="Новый пароль"
              />
              <input
                className="profile-page-change-password-input"
                onChange={(e) => setFieldValue("checkPassword", e.target.value)}
                value={values.checkPassword}
                type="text"
                placeholder="Повторите пароль"
              />
              <button
                className="button-default"
                type={"button"}
                onClick={handleSubmit}>
                Сменить
              </button>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
});
